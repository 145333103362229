@import 'webresources-sources/scss/imports';

.filter-tags {
  .filter-list {
    label {
      input ~ .btn {
        background-color: $color-white;
        color: $color-gray-600;
        border: 1px solid $color-darkgreen-600;
        font-weight: $font-weight-normal;

        .bg-dark & {
          background-color: $color-darkgray-700;
          color: $color-bluegray-400;
          border: 1px solid $color-darkgreen-100;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color-darkgreen-200;
            border-color: $color-darkgreen-600;
            color: $color-darkgreen-600;

            .bg-dark & {
              background-color: $color-darkgreen-600;
              border-color: $color-darkgreen-300;
              color: $color-bluegray-400;
            }
          }
        }

        &:active {
          opacity: 0.8;
        }
      }

      input:checked ~ .btn {
        background-color: $color-darkgreen-600;
        border-color: $color-darkgreen-600;
        color: $color-white;

        .bg-dark & {
          background-color: $color-darkgreen-200;
          border-color: $color-darkgreen-200;
          color: $color-darkgray-900;
        }
      }
    }
  }

  .checkbox-list {
    input {
      border: 1px solid $color-gray-600;

      &:checked {
        background-color: $color-darkgreen-600;
        border-color: $color-darkgreen-600;
      }
    }

    label {
      color: $color-darkgray-700;
    }

    input:checked ~ label {
      color: $color-darkgray-700;
    }
  }
}
