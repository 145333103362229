/* bootstrap overwrite */
.filter-tags .filter-list label input ~ .btn {
  background-color: #fff;
  color: #657785;
  border: 1px solid #007a62;
  font-weight: 400;
}
.bg-dark .filter-tags .filter-list label input ~ .btn {
  background-color: #546983;
  color: #e4eaf7;
  border: 1px solid #dff7f2;
}
@media (hover: hover) {
  .filter-tags .filter-list label input ~ .btn:hover {
    background-color: #bcf0e8;
    border-color: #007a62;
    color: #007a62;
  }
  .bg-dark .filter-tags .filter-list label input ~ .btn:hover {
    background-color: #007a62;
    border-color: #87e0cf;
    color: #e4eaf7;
  }
}
.filter-tags .filter-list label input ~ .btn:active {
  opacity: 0.8;
}
.filter-tags .filter-list label input:checked ~ .btn {
  background-color: #007a62;
  border-color: #007a62;
  color: #fff;
}
.bg-dark .filter-tags .filter-list label input:checked ~ .btn {
  background-color: #bcf0e8;
  border-color: #bcf0e8;
  color: #38506f;
}
.filter-tags .checkbox-list input {
  border: 1px solid #657785;
}
.filter-tags .checkbox-list input:checked {
  background-color: #007a62;
  border-color: #007a62;
}
.filter-tags .checkbox-list label {
  color: #546983;
}
.filter-tags .checkbox-list input:checked ~ label {
  color: #546983;
}